<template>
  <div>
    <div class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
      <div class="col-sm-12" style="padding-left:0px; padding-right: 0px;">
        <div class="col-lg-3 col-md-3 col-sm-12" style="padding-left:0px; padding-right: 0px;">
          <input
            style="margin-top: 10px;"
            type="text"
            v-model="searchKey"
            class="form-control"
            id="input-store"
            placeholder="Search Product"
            value
            name="voucher"
          />
        </div>
        <div
          class="col-lg-9 col-md-9 col-sm-12 text-right"
          style="padding-left:0px; padding-right: 10px; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-end; align-items: center; align-content: stretch;"
        >
          <div style="margin-top: 10px;">
            From
            <datepicker
              style="display:inline-block; margin-right: 10px"
              v-model="filterDatefrom"
              placeholder="Select Date"
            ></datepicker>
          </div>
          <div style="margin-top: 10px;">
            To
            <datepicker
              style="display:inline-block; margin-right: 10px"
              calendar-class="my-datepicker_calendar"
              v-model="filterDateto"
              placeholder="Select Date"
            ></datepicker>
          </div>
          <div style="margin-top: 10px;">
            Status
            <select
              v-model="selectedFilterStatus"
              style="display:inline-block; width: 100px; margin-right: 10px"
              class="form-control"
            >
              <option
                v-for="(item, index) in filterStatusArray"
                :key="index"
                :value="item.order_line_status"
              >{{item.text}}</option>
            </select>
          </div>
        </div>
        <div
          class="col-lg-12 col-md-12 col-sm-12 text-right"
          style="padding-left: 0px; padding-right: 10px; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-end; align-items: center; align-content: stretch;"
        >
          <div style="margin-top: 10px;">
            Payment Method
            <select
              v-model="selectedPaymentFilterStatus"
              style="display: inline-block; width: 200px; margin-right: 18px; margin-left: 5px"
              class="form-control"
            >
              <option
                v-for="(item, index) in filterPaymentArray"
                :key="index"
                :value="item.payment_type"
              >{{item.text}}</option>
            </select>
          </div>
          <div style="margin-top: 10px;">
            <select
              v-model="selectedFilterStatusProvince"
              style="display: inline-block; width: 200px; margin-right: 18px"
              class="form-control"
              @change="getCities(selectedFilterStatusProvince)"
            >
              <option
                v-for="(item, index) in provinceArray"
                :key="index"
                :value="item._id"
                :disabled="item._id === '0'"
              >{{item.region_name}}</option>
            </select>
          </div>
          <div style="margin-top: 10px;">
            <select
              v-model="selectedFilterStatusDistrict"
              style="display: inline-block; width: 200px; margin-right: 18px"
              class="form-control"
            >
              <option
                v-for="(item1, index) in districtArray"
                :key="index"
                :value="item1._id"
                :disabled="item1._id === '0'"
              >{{item1.city_name}}</option>
            </select>
          </div>
          <div style="margin-top: 10px;">
            <button
              @click="getOrders();"
              style="vertical-align: inherit; margin-right: 12px;"
              class="btn merchprof-sbtn"
            >Filter</button>
          </div>
        </div>
      </div>
      <div class="col-sm-12" style="padding-left: 0px;">
        <h2 class="title">Orders</h2>
      </div>
    </div>
    <div id="table-content" class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
      <table id="example" class="table table-striped table-border-out">
        <thead>
          <tr>
            <!-- <th scope="col" class="head-table"></th> -->
            <th scope="col" class="head-table">Order Id</th>
            <th scope="col" class="head-table">Status</th>
            <th scope="col" class="head-table">Quantity</th>
            <th scope="col" class="head-table">Amount</th>
            <th scope="col" class="head-table">Payment Method</th>
            <th scope="col" class="head-table">Action</th>
          </tr>
        </thead>
        <tbody>
          <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center">
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </div>
          </div>

          <tr v-for="(itemp, index) in tableItems" :key="index">
            <!-- <td class="text-center col-md-1"><a><img width="70px" :src="itemp.simple_product_id.images[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail"></a></td> -->

            <td class="text-left col-md-4">
              <!-- <div class="row">
                <div class="col-md-12" style="font-size:14px; margin-bottom:5px;">								
                  <a><b>{{ itemp.simple_product_id.product_id.product_name }}</b></a>
                </div>
              </div>-->
              <!-- <div class="row">
                <div class="col-md-6 col-sm-6">								
                  Order ID : {{ itemp.order_env_number }}
                </div>
                <div class="col-md-6 col-sm-6">								
                  Date : {{ itemp.date }}
                </div>
              </div>-->
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <span style="font-size: 15px;">
                    <b>{{ itemp.order_env_number }}</b>
                  </span>
                  <br />
                  <span
                    v-if="itemp.order_id.billing_name !== 'undefined undefined'"
                  >{{ itemp.order_id.billing_name }}</span>
                </div>
                <div class="col-md-6 col-sm-6">
                  Date : {{ itemp.date }}
                  <br />
                  {{ itemp.order_id.billing_address_id.city.city_name }}
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-6 col-sm-6" v-for="(itemp1, index1) in itemp.simple_product_id.attributes" :key="index1" >								
                  {{ itemp1.attribute_id.attribute_name }} : {{ itemp1.attribute_value_id.lable}}
                </div>
              </div>-->
            </td>

            <td class="text-center col-md-2" style="vertical-align: middle; text-align: left;">
              <span
                class="badge"
                style="background-color: rgb(225, 83, 55);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 0"
              >
                <B>Cancelled</B>
              </span>
              <span
                class="badge"
                style="background-color: #f1ca16;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 1"
              >
                <B>Pending</B>
              </span>
              <span
                class="badge"
                style="background-color: #f1ca16;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 10"
              >
                <B>Processing</B>
              </span>
              <span
                class="badge"
                style="background-color: rgb(0, 89, 170);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 20"
              >
                <B>Ready to Ship</B>
              </span>
              <span
                class="badge"
                style="background-color: #A32BB9;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 30"
              >
                <B>Shipped</B>
              </span>
              <span
                class="badge"
                style="background-color: #4da130;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 40"
              >
                <B>Delivered</B>
              </span>
              <span
                class="badge"
                style="background-color: #17c017;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 41"
              >
                <B>Delivery Confirmed</B>
              </span>
              <span
                class="badge"
                style="background-color: rgb(225, 83, 55);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 50"
              >
                <B>Fail to Delived</B>
              </span>
              <span
                class="badge"
                style="background-color: rgb(0, 91, 170);border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 60"
              >
                <B>Return to Store</B>
              </span>
              <span
                class="badge"
                style="background-color: #005baa;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                v-if="itemp.order_line_status === 70"
              >
                <B>Accept Return</B>
              </span>
              <!-- <div class="left-content-product">
                <div class="content-product-right">	
                  
                    <div class="form-group box-info-product">
                      <div class="option quantity">
                        <div class="input-group quantity-control" v-bind:style="{ background: returnactiveColor(itemp.order_line_status) }" unselectable="on" style="-webkit-user-select: none; margin-top: 16px; border-radius: 4px;">
                          <span v-if="itemp.order_line_status === 0"><B>Cancelled</B></span>
                          <span v-if="itemp.order_line_status === 1" style="color:black;font-weight:600;">Pending</span>
                          <span v-if="itemp.order_line_status === 10"><B>Processing</B></span>
                          <span v-if="itemp.order_line_status === 20"><B>Ready to Pickup</B></span>
                          <span v-if="itemp.order_line_status === 30"><B>Shipped</B></span>
                          <span v-if="itemp.order_line_status === 40"><B>Deliverd</B></span>
                          <span v-if="itemp.order_line_status === 50"><B>Fail to Delived</B></span>
                          <span v-if="itemp.order_line_status === 60"><B>Return to Store</B></span>
                          <span v-if="itemp.order_line_status === 70"><B>Accept Return</B></span>
                        </div>
                      </div>			 
                    </div>						
                              
                </div>
              </div>-->
            </td>

            <td
              class="text-right"
              style="vertical-align: middle; text-align: left; font-weight: 700;"
            >
              <B>{{ itemp.qty }}</B>Items
            </td>
            <td
              class="text-right"
              style="vertical-align: middle; text-align: left; font-weight: 700;"
            >
              <B>Rs:</B>
              {{ itemp.total_charge }}
            </td>
            <td
              class="text-left"
              style="vertical-align: middle; font-weight: 700;"
              v-if="itemp.payment_type === 1"
            >Supiri Pola Credit</td>
            <td
              class="text-left"
              style="vertical-align: middle; font-weight: 700;"
              v-if="itemp.payment_type === 2"
            >Cash On Delivery</td>
            <td
              class="text-left"
              style="vertical-align: middle; font-weight: 700;"
              v-if="itemp.payment_type === 3"
            >Bank Transfer</td>
            <td
              class="text-left"
              style="vertical-align: middle; font-weight: 700;"
              v-if="itemp.payment_type === 4"
            >Card Payment</td>

            <td style="vertical-align: middle; text-align: left;">
              <button
                type="button"
                data-toggle="tooltip"
                title
                style="background: #ffd338;"
                class="btn merchprof-sbtn"
                onclick
                data-original-title="View"
                @click="handdleChange(itemp)"
              >View</button>
              <button
                type="button"
                data-toggle="tooltip"
                title
                style="background: #ffd338; margin-left: 10px;"
                class="btn merchprof-sbtn"
                onclick
                data-original-title="View"
                @click="handlePrint(itemp._id)"
              >Print</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination
            v-model="currentPage"
            :per-page="pageSize"
            :total-rows="rowcount"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Domain from "@/mixins/domain.js";
import Datepicker from "vuejs-datepicker";
import mixinOrder from "@/mixins/merchant/APIOrder";
export default {
  mixins: [mixinOrder],
  components: {
    Datepicker
  },
  props: {
    value: {
      type: String
    }
  },
  data: function() {
    return {
      selected_store: "",
      viewType: "all", // data to be viewed in the table (by store name or all orders) all || viewType
      breadcrumb: [
        {
          text: "Dashboard",
          href: "/merchant-dashbord",
          active: false
        },
        {
          text: "Order List",
          active: true
        }
      ],
      subTotal: 0,
      total: 0,
      selectedPaymentFilterStatus: "",
      selectedFilterStatusProvince: "0",
      selectedFilterStatusDistrict: "0",
      provinceArray: [{ _id: "0", region_name: "Select Privince" }],
      districtArray: [{ _id: "0", city_name: "Select District" }],
      filterPaymentArray: [
        { payment_type: "", text: "All" },
        { payment_type: "1", text: "Supiri Pola Credit" },
        { payment_type: "2", text: "Cash On Delivery" },
        { payment_type: "3", text: "Bank Transfer" },
        { payment_type: "4", text: "Card Payment" }
      ],
      inputNumValue: 1,
      no_of_cart_items: 0,
      cart_products: [],
      cartSavedProductIds: [],
      idObj: {
        ids: []
      },
      selectedArray: [],
      pageSize: 25,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
      pendingOrderArray: [],
      blockLoading: true,
      filterDatefrom: new Date(),
      filterDateto: new Date(),
      filterStatusArray: [
        { order_line_status: "", text: "All" },
        { order_line_status: "0", text: "Cancelled" },
        { order_line_status: "1", text: "Pending" },
        { order_line_status: "10", text: "Processing" },
        { order_line_status: "20", text: "Ready to Ship" },
        { order_line_status: "30", text: "Shipped" },
        { order_line_status: "40", text: "Delivered" },
        { order_line_status: "50", text: "Fail to Delivered" },
        { order_line_status: "60", text: "Return to Store" },
        { order_line_status: "70", text: "Accept Return" },
        { order_line_status: "41", text: "Delivery Confirmed" }
      ],
      selectedFilterStatus: ""
    };
  },
  watch: {
    "$store.state.merchant_selectedstore": function() {},
    value(val) {
      this.selected_store = val;
      if (val != -1 && val) {
        this.getOrdersByStore();
        this.viewType = "default";
      }
      if (val == -1) {
        this.getOrders();
        this.viewType = "all";
      }
    },
    searchKey: function(val) {
      this.searchOrders(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {}
  },
  created() {
    this.handleBreadcrumb();
    this.filterDatefrom.setDate(this.filterDatefrom.getDate() - 14);
    this.getOrders();
    this.filterPendingOrder();
    this.getProvince();
  },
  methods: {
    filterPendingOrder() {
      if (
        this.$router.history.current.params.type === "Pending Orders" &&
        this.$router.history.current.params.type !== undefined
      ) {
        this.selectedFilterStatus = 1;
        this.getOrders();
      }
    },
    getCities: async function(val) {
      try {
        this.districtArray = await this.getCityList(val);
        this.districtArray.unshift({ _id: "0", city_name: "Select District" });
        this.selectedFilterStatusDistrict = "0";
      } catch (error) {
        throw error;
      }
    },
    getProvince: async function() {
      try {
        this.provinceArray = await this.getProvinceList();
        this.provinceArray.unshift({
          _id: "0",
          region_name: "Select Province"
        });
      } catch (error) {
        throw error;
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    returnactiveColor(status) {
      if (status === 0 || status === 50) {
        return "#e15337";
      }
      if (status === 1 || status === 10) {
        return "#fecd36";
      }
      if (status === 40) {
        return "#4da130";
      }
      return "#005baa";
    },
    getOrdersByStore: async function() {
      try {
        this.blockLoading = true;
        let response = await this.getOrdersByStoreApi(this.selected_store);
        if (response) {
          this.cart_products = response.map(obj => {
            let dats = obj.order_id.date.split("GMT");
            obj.date = dats[0];
            return obj;
          });
          let pagesize = parseInt(this.pageSize);
          this.storeListData = this.cart_products;
          this.tableItems = this.storeListData.slice(
            this.currentPage * pagesize - pagesize,
            this.currentPage * pagesize
          );
          this.rowcount = response.length;
          this.blockLoading = false;
        }
      } catch (e) {
        throw e;
      }
    },
    handlePrint: async function(id) {
      try {
        let res = await this.printOrderDetails(id);
        Domain.APP_DOMAIN = Domain.APP_DOMAIN.split("api/v1/")[0];
        window.open(Domain.APP_DOMAIN + res.file);
      } catch (error) {
        throw error;
      }
    },
    getOrdersCount: async function() {
      try {
        let params = "";
        if (this.selectedFilterStatus != "") {
          params += "&order_line_status=" + this.selectedFilterStatus;
        }
        if (this.searchKey != "") {
          params += "&search=" + this.searchKey;
        }
        if (this.selectedPaymentFilterStatus !== "") {
          params += "&payment_type=" + this.selectedPaymentFilterStatus;
        }
        if (
          this.selectedFilterStatusDistrict !== "" &&
          this.selectedFilterStatusDistrict !== "0"
        ) {
          params += "&city=" + this.selectedFilterStatusDistrict;
        }
        params +=
          "&from_date=" + moment(this.filterDatefrom).format("YYYY-MM-DD");
        params += "&to_date=" + moment(this.filterDateto).format("YYYY-MM-DD");
        let responce = await this.getOrderListCount(params);
        this.rowcount = responce.count;
      } catch (error) {
        throw error;
      }
    },
    getOrders: async function() {
      try {
        this.blockLoading = true;
        let params = "&page=" + this.currentPage;
        params += "&perpage=" + 5;
        if (this.selectedPaymentFilterStatus !== "") {
          params += "&payment_type=" + this.selectedPaymentFilterStatus;
        }
        if (
          this.selectedFilterStatusDistrict !== "" &&
          this.selectedFilterStatusDistrict !== "0"
        ) {
          params += "&city=" + this.selectedFilterStatusDistrict;
        }
        if (this.selectedFilterStatus != "") {
          params += "&order_line_status=" + this.selectedFilterStatus;
        }
        if (this.searchKey != "") {
          params += "&search=" + this.searchKey;
        }
        params +=
          "&from_date=" + moment(this.filterDatefrom).format("YYYY-MM-DD");
        params += "&to_date=" + moment(this.filterDateto).format("YYYY-MM-DD");
        let responce = await this.getOrderList(params);
        this.cart_products = responce.map(obj => {
          let dats = obj.order_id.date.split("GMT");
          obj.date = dats[0];
          return obj;
        });
        this.getOrdersCount();
        this.storeListData = this.cart_products;
        this.tableItems = this.cart_products;
        this.blockLoading = false;
      } catch (error) {
        throw error;
      }
    },
    searchOrders: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.storeListData.slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      } else {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                (obj.order_env_number &&
                  obj.order_env_number
                    .toLowerCase()
                    .includes(keyword.toLowerCase())) ||
                (obj.simple_product_id.sku &&
                  obj.simple_product_id.sku
                    .toLowerCase()
                    .includes(keyword.toLowerCase())) ||
                (obj.date &&
                  obj.date.toLowerCase().includes(keyword.toLowerCase()))
              );
            }
          })
          .slice(0, pagesize);
        this.rowcount = this.tableItems.length;
      }
      this.blockLoading = false;
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      this.currentPage = currentPage;
      if (this.viewType == "all") {
        this.getOrders();
      } else {
        this.getOrdersByStore();
      }
    },
    handdleChange: function(item) {
      this.$router.push({ name: "Order Configure", params: { id: item._id } });
    }
  }
};
</script>

<style scoped>
.vdp-datepicker input {
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

.merchprof-sbtn {
  background: #ffd338;
  color: #000000;
  border: none;
}
.merchprof-sbtn:hover {
  color: #ffffff;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  /* top: 0; */
  left: 0;
  /* bottom: 60px; */
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.head-table {
  background: white !important;
}
.table-border-out {
  border: 1px solid #ddd;
}

@media screen and (max-width: 1280px) {
  #table-content {
    overflow-x: scroll;
  }

  table {
    width: 960px !important;
  }

  .img-thumbnail {
    max-width: 80px;
  }
}
</style>