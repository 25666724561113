import Domain from "../domain";
export default {
    methods: {
        getOrderListGroup: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/new/details?" + params,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getOrderLinesByOrderId: async function(orderId) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/new/details/" + orderId,
                    Domain.APP_MERCHANT_HEADER
                );
                // console.log("response.data",response.data);
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getOrderList: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/details?" + params,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProvinceList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN +
                    "common/country/region/details/5d932c7a570adc5007bdb5dc",
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCityList: async function(val) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/country/city/details/" + val,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        printOrderDetails: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN +
                    // "merchant/order/group/details/sathosa/invoice/" +
                    "merchant/order/group/details/invoice/" +
                    id,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postPrintOrderDetails: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "merchant/order/bill/print", obj,
                    Domain.APP_MERCHANT_HEADER
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        getOrdersByStoreApi: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/new/details?store=" + params, // theekshana dev
                    // Domain.APP_DOMAIN + "merchant/order/details?store="+ params,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getOrderListCount: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/details/count?" + params,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getOrderById: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/details/" + id,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusProcessingById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/process",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusPickupById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/ready",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusShippedById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/shipped",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusAcceptReturnById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/accept/return",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReturnOrderCount: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/count/dispute/details?" + params,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReturnOrder: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/dispute/details?" + params,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReturnOrderById: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/dispute/details?order_no=" + id,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusMerchanetApproveById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/dispute/approve",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusMerchantRejectById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/dispute/reject",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusMerchantRetuenApproveById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/dispute/return/accept",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putBankSlipImage: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/bank/upload/image",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusMerchantMoneyPassById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/dispute/money/pass",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReturnOrderStatusHistory: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/dispute/history/details/" + id,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postDisputeChat: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/product/dispute/chat",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getDisputeChat: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/product/dispute/chat/" + id,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getMerchantOrderNotification: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/notification/",
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getMerchantOrderNotificationByPagination: async function(page, perpage) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN +
                    "merchant/order/notification?page=" +
                    page +
                    "&perpage=" +
                    perpage,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        chgPendingToProcessing: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/process/bulk",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        chgProcessingToReady: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/ready/bulk",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        chgReadyToShipped: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/status/shipped/bulk",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putCancelOrder: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/cochchi/cancel",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        haddleGetMerchatCancelReasonList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "merchant/order/cancel/reasons",
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putchangeOderCancel: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "merchant/order/cochchi/cancel",
                    obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        }
    }
};